
import { Vue, Component } from "vue-property-decorator";
import { getStartingLocale } from "@/i18n-setup";

interface BookedSessionData {
  therapistPhoto: string | null;
  therapistFirstname: string | null;
  therapistLastname: string | null;
  sessionDate: string | null;
  sessionAdditional: string | null;
}

@Component({})
export default class PaymentSuccess extends Vue {
  baseUrl = process.env.VUE_APP_TARGET;

  sessionData: BookedSessionData = {
    therapistPhoto: "",
    therapistFirstname: "",
    therapistLastname: "",
    sessionDate: "",
    sessionAdditional: "",
  };

  created() {
    this.loadSavedData();
  }

  loadSavedData() {
    const retrieved_data: any = sessionStorage.getItem("booked_session_data");
    const data = JSON.parse(retrieved_data);
    this.sessionData = data;
  }

  get visitDate(): string {
    const date = new Date(Number(this.sessionData.sessionDate));
    const month = new Intl.DateTimeFormat(getStartingLocale(), {
      month: "long",
    }).format(date);
    const weekday = new Intl.DateTimeFormat(getStartingLocale(), {
      weekday: "long",
    }).format(date);
    return month + " " + date.getDate() + ", " + weekday;
  }

  get visitTime(): string {
    return new Intl.DateTimeFormat(getStartingLocale(), {
      hour: "numeric",
      minute: "numeric",
    }).format(Number(this.sessionData.sessionDate));
  }
}
